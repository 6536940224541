import { getFormProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { type LinksFunction } from '@remix-run/node'
import { Link, useFetcher, useNavigation } from '@remix-run/react'
import { useEffect, useRef, useState } from 'react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { Button } from '#app/components/buttons/button'
import { Container } from '#app/components/layout/container'
import { Flex } from '#app/components/layout/flex'
import { VisuallyHidden } from '#app/components/layout/visually-hidden'
import { Icon } from '#app/components/media/icon'
import { Tooltip, TooltipTrigger } from '#app/components/overlays/tooltip'
import { H2 } from '#app/components/typography/h2'
import { Lead } from '#app/components/typography/lead'
import { P } from '#app/components/typography/p'
import { Q } from '#app/components/typography/q'
import loginForm from '#app/routes/_marketing+/login_form.png'
import { type action, newsletterSchema } from '#app/routes/_marketing+/newsletter'

export const links: LinksFunction = () => {
	return [{ rel: 'canonical', href: `https://verveui.pro` }]
}

export default function Index() {
	const fetcher = useFetcher<typeof action>()
	const navigation = useNavigation()
	const [state, setState] = useState<'initial' | 'success'>('initial')
	const [form, { email }] = useForm({
		lastResult: fetcher.data,
		shouldValidate: 'onSubmit',
		shouldRevalidate: 'onInput',
		onValidate: ({ formData }) => parseWithZod(formData, { schema: newsletterSchema }),
	})
	const ref = useRef<HTMLFormElement | null>(null)

	useEffect(() => {
		if (fetcher.data?.status === 'success') {
			setState('success')
		}
	}, [fetcher.data])

	return (
		<>
			<main className="min-h-[calc(100vh-var(--header-height))]">
				<Flex justify="center" items="center" className="relative isolate min-h-[calc(100vh-var(--header-height)-50px)] overflow-hidden">
					<div className="from-background h-fluid-17 absolute inset-x-0 top-0 bg-gradient-to-b" aria-hidden="true" />
					<svg className="stroke-muted-4 absolute inset-0 -z-10 h-full w-full [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
						<defs>
							<pattern id="dark_pattern" width={200} height={200} x="50%" y={-1} patternUnits="userSpaceOnUse">
								<path d="M.5 200V.5H200" fill="none" />
							</pattern>
						</defs>
						<rect width="100%" height="100%" strokeWidth={0} fill="url(#dark_pattern)" />
					</svg>
					<div className="absolute top-10 left-[calc(50%-18rem)] left-[calc(50%-4rem)] -z-10 transform-gpu blur-3xl" aria-hidden="true">
						<div
							className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80ffa0] to-[#46e5b0] opacity-20"
							style={{
								clipPath:
									'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
							}}
						/>
					</div>
					<Container>
						<Flex orientation="vertical" items="center" gap="10" className="py-fluid-17">
							<h1 className="from-muted-950 to-muted-700 text-fluid-6xl [overflow-wrap:anywhere] bg-gradient-to-b bg-clip-text pr-[calc(var(--letter-spacing-tighter)*-1)] pb-[0.06em] text-center font-bold text-transparent">
								Ship faster with beautiful components
							</h1>
							<Lead className="text-center">Complete UI library with production-ready and composable components that consume a beautiful and customizable design system.</Lead>
							<img src={loginForm} alt="Login form" className="h-auto w-full max-w-[500px]" />
							<div className="max-w-[500px]">
								<Q className="text-muted-500 text-center">VerveUI has been a game changer. We're able to ship faster and with more confidence than ever before.</Q>
								<p className="text-muted-700 text-center">— André Casal</p>
							</div>
							<div>
								<P className="text-muted-500 text-center">
									We're building something amazing.
									<br /> Join our list to see each new component as it pops out.
								</P>
								<div className="w-full max-w-sm">
									{state !== 'success' ?
										<fetcher.Form
											ref={ref}
											method="post"
											action="/newsletter"
											className={`${state === 'initial' ? ' opacity-100' : 'pointer-events-none opacity-0'}`}
											{...getFormProps(form)}
										>
											<p>{form.errors}</p>
											<HoneypotInputs />
											<Flex justify="between" gap="4" wrap>
												<Flex orientation="vertical" gap="2" className="max-w-[320px] min-w-[50%] flex-1">
													<VisuallyHidden>
														<label htmlFor="email-address">Email address</label>
													</VisuallyHidden>
													<input
														type="email"
														name={email.name}
														id="email-address"
														autoComplete="email"
														required
														className="bg-muted-100 h-fluid-15 border-muted-200 placeholder:text-muted-500 text-muted-600 box-content rounded-2xl border-2 px-4 text-sm outline-none focus-visible:ring-2"
														placeholder="Enter your email"
														defaultValue={email.initialValue}
													/>
													<Flex orientation="vertical" gap="2" className="ml-4">
														{email?.errors?.map(error => (
															<p key={error} className="text-danger-text-soft text-xs">
																{error}
															</p>
														))}
													</Flex>
												</Flex>
												<div className="h-fluid-15 relative box-content overflow-hidden rounded-2xl p-0.5">
													<div className="animate-eclipse bg-foreground absolute -top-0.5 right-0 -bottom-0.5 left-0 -z-[1] translate-x-10 blur-md" />
													<Button
														size="md"
														variant="ghost"
														type="submit"
														isDisabled={navigation.state === 'submitting'}
														className="ring-muted-700/50 data-[hovered]:bg-muted-200 bg-muted-100 text-muted-700 rounded-2xl outline-none data-[focus-visible]:ring-2"
													>
														Join list
													</Button>
												</div>
											</Flex>
										</fetcher.Form>
									:	<Flex justify="center" gap="4">
											<p
												onClick={() => {
													ref.current?.reset()
													setState('initial')
												}}
												className="cursor-pointer text-sm"
											>
												You're subscribed <Icon name="check" className="text-green-500" />
											</p>
										</Flex>
									}
								</div>
							</div>
						</Flex>
					</Container>
					<div className="from-background h-17 absolute inset-x-0 bottom-0 bg-gradient-to-t" aria-hidden="true" />
				</Flex>
				<Flex justify="center" items="center" className="h-[50px]">
					<Link to="#comparison" className="mb-[4px] rounded-md">
						<VisuallyHidden>Scroll down to comparison section</VisuallyHidden>
						<Icon name="chevron-down" className="text-muted-500 h-fluid-15 w-fluid-15 animate-bounce [animation-duration:2000ms] [animation-timing-function:ease-in-out]" />
					</Link>
				</Flex>
			</main>
			<Container className="min-h-[calc(100vh-var(--footer-height))] overflow-hidden">
				<section id="comparison" className="py-16">
					<H2 className="mb-fluid-15 text-muted-950 text-fluid-4xl [overflow-wrap:anywhere] text-center">How VerveUI compares to other UI libraries</H2>
					<div className="overflow-x-auto">
						<table className="mt-6 mx-auto mb-1 whitespace-nowrap text-left">
							<colgroup>
								<col className="" />
								<col className="" />
								<col className="" />
								<col className="" />
								<col className="" />
								<col className="" />
								<col className="" />
								<col className="" />
								<col className="" />
							</colgroup>
							<thead className="border-foreground/10 text-foreground border-b text-sm leading-6">
								<tr className="text-center">
									<th scope="col">&nbsp;</th>
									<th scope="col" className="py-fluid-2 pr-fluid-8 pl-fluid-4 font-semibold">
										Material UI
									</th>
									<th scope="col" className="py-fluid-2 pr-fluid-8 pl-fluid-4 font-semibold">
										TailwindUI
									</th>
									<th scope="col" className="py-fluid-2 pr-fluid-8 pl-fluid-4 font-semibold">
										shadcn/ui
									</th>
									<th scope="col" className="py-fluid-2 pr-fluid-8 pl-fluid-4 font-semibold">
										Flowbite
									</th>
									<th scope="col" className="py-fluid-2 pr-fluid-8 pl-fluid-4 font-semibold">
										DaisyUI
									</th>
									<th scope="col" className="py-fluid-2 pr-fluid-8 pl-fluid-4 font-semibold">
										NextUI
									</th>
									<th scope="col" className="py-fluid-2 pr-fluid-8 pl-fluid-4 font-semibold">
										Mantine
									</th>
									<th scope="col" className="py-fluid-2 pr-fluid-8 pl-fluid-4 font-semibold">
										ChakraUI
									</th>
									<th scope="col" className="py-fluid-2 pr-fluid-8 pl-fluid-4 font-semibold">
										VerveUI
									</th>
								</tr>
							</thead>
							<tbody className="text-muted-600 divide-y divide-white/5 text-sm">
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 text-md gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left font-bold">
												<span className="text-muted-500">Design System</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Explicitly built around a complete design system out of the box.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Escapable</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Easily remove the entire design system or any part of it.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Fully customizable</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">You have complete control over the design system. Change any part of it to suit your needs.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Semantic tokens</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Semantic tokens provide a single source of truth for styles, making it much faster to build and maintain your UI.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Decoupled</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">
												The styles live outside the component code to avoid context switching between building and designing. When you're building you're building. When you're
												desining, you're designing.
											</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Colocated</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">
												The design system (tokens, utility classes, and component styles) are colocated in a single, incredibly small, CSS file. This makes it much easier to see
												how changes affect your design.
											</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Beautiful</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Comes with beautifully crafted components out of the box.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Themable</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Theming support besides mere color schemes, that include typography, spacing, and more.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 text-md gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left font-bold">
												<span className="text-muted-500">Components</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Comes with components out of the box.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Fully customizable</span>
												<Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">You have complete control over the component code. Change any part of it to suit your needs.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Independent</span>
												<Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">The code is yours. There are no dependencies to update. Ever.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Complete</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">The range and diversity of components offered is enough for building most UIs.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Variants</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Supports component variants.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">React Aria</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Is based on React Aria, the gold standard in accessibility, usability and keyboard navigation.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">i18n</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Comes with RTL support and no hard-coded text.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">TypeScript</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">Components are written in TypeScript for type safety and autocompletion support.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
								<tr className="hover:bg-muted-100 text-center">
									<td className="pr-fluid-8 pl-fluid-4">
										<TooltipTrigger delay={200}>
											<Button variant="ghost" className="text-muted-900 gap-fluid-6 flex w-full items-center justify-between py-2 px-3 text-left text-sm font-medium leading-6">
												<span className="pl-8">Composable</span> <Icon name="info" className="h-fluid-8 w-fluid-8" />
											</Button>
											<Tooltip side="top">The components give you a composable, instead of a props-based, API.</Tooltip>
										</TooltipTrigger>
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="minus" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
									<td className="text-md py-fluid-2 pr-fluid-8 pl-fluid-4">
										<Icon name="check" className="text-green-500" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
			</Container>
		</>
	)
}
