import { parseWithZod } from '@conform-to/zod'
import { json, type ActionFunctionArgs } from '@remix-run/node'
import { Resend } from 'resend'
import { z } from 'zod'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { EmailSchema } from '#app/utils/user-validation.ts'

export const newsletterSchema = z.object({
	email: EmailSchema,
})

export const newsletterEmailQueryParam = 'email'
export const newsletterOTPQueryParam = 'code'
export const newsletterVerificationType = 'newsletter'

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()

	// Check for bots
	checkHoneypot(formData, '/newsletter')

	// Parse form
	const submission = parseWithZod(formData, { schema: newsletterSchema })
	if (submission.status !== 'success') {
		return json(submission.reply(), { status: submission.status === 'error' ? 400 : 200 })
	}

	// Extract data
	const { email } = submission.value

	// Subscribe to newsletter
	const resend = new Resend(process.env.RESEND_API_KEY)
	const result = await resend.contacts.create({
		email,
		unsubscribed: false,
		audienceId: process.env.RESEND_GENERAL_AUDIENCE,
	})
	if (result.error) {
		return json(submission.reply({ formErrors: ['Adding contact failed. Please try again.'] }))
	}

	// Everything ok
	return json({ status: 'success', submission } as const)
}
